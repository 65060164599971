<template>
  <b-card title="">
    <b-row>
      <b-col cols="6">
        <h3 class="text-primary">Application Edit</h3>
      </b-col>
      <b-col cols="6">
        <b-button
          variant="primary"
          class="btn btn-primary float-right"
          @click="back()"
          size="sm"
        >
          Back
        </b-button>
      </b-col>

      <b-col cols="12">
        <b-row>
          <b-col cols="6">
            <b-form-group label="Application Name" label-for="v-first-name">
              <b-form-input id="v-first-name" v-model="applicationName" />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Application Link" label-for="v-first-name">
              <b-form-input id="v-first-name" v-model="applicationLink" />
            </b-form-group>
          </b-col>
          <!-- <b-col cols="6">
            <b-form-group label="Firewall" label-for="v-first-name"  v-for="nam in firewall" :key="nam">
              <b-form-input
                id="v-first-name"
                readonly
              
               
              >{{nam.name}}</b-form-input>
            </b-form-group>
          </b-col> -->
          <!-- {{cloudProfiles}} -->
          <b-col cols="12">
            <b-form-group label="Application Details" label-for="v-first-name">
              <!-- <label for="textarea-default">Textarea</label> -->
              <b-form-textarea
                v-model="applicationDetails"
                id="textarea-default"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Cloud Profile" label-for="v-first-name">
              <select
                class="form-control"
                v-model="cloudProfiles"
                @change="firewallUnderCloud()"
              >
                <option
                  v-for="cloud in cloudProfileOptionss"
                  :key="cloud.id"
                  :value="cloud.id"
                >
                  {{ cloud.name }}
                </option>
              </select>
              <!-- <b-form-select v-model="cloudProfiles" @change="abc()">
                <b-form-select-option
                  v-for="cloud in cloudProfileOptionss"
                  :key="cloud.id"
                  :value="cloud"
                  >{{ cloud.name }}</b-form-select-option
                >
              </b-form-select>
              {{ cloudProfiles }} -->
              <!-- <v-select
                v-model="cloudProfiless"
                :options="cloudProfileOptionss"
                label="name"
              /> -->
              <!-- <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Firewalls" label-for="v-first-name">
              <!-- <validation-provider
                    #default="{ errors }"
                    name="Application List"
                    rules="required"
                  > -->
              <v-select
                v-model="firewalls"
                multiple
                :options="firewallOptions"
                label="name"
                taggable
              />

              <!-- <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
            </b-form-group>
            <!-- {{ firewalls }} -->

            <!-- <p>{{firewall_options}} with out clouddata</p> -->
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <b-form-checkbox
                id="checkbox-10"
                name="checkbox-10"
                :checked="publicApplication"
                v-model="publicApplication"
              >
                Public Application
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <b-form-checkbox
                id="checkbox-11"
                name="checkbox-11"
                :checked="autoEnroll"
                v-model="autoEnroll"
              >
                Automatic Enroll
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-row class="ty-3">
              <b-col class="col-12">
                <h3 class="text-primary d-flex align-items-center my-2">
                  <!-- <feather-icon
                        icon="FileTextIcon"
                        size="20"
                        class="mr-0 mr-sm-50"
                      /> -->
                  Ports
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                    class="btn-icon rounded-circle ml-2"
                    @click="addField(k)"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </h3>
                <!-- <p>Attach All Mandatory Documents.</p>
                    <div v-for="doc in selectDocument" :key="doc">
                      <p class="my-2">
                        {{ doc.document_name
                        }}<b-form-file
                          v-model="file"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        />Upload {{ doc.file_type }} file
                      </p>
                      <hr />
                    </div> -->
              </b-col>
              <b-col class="col-12">
                <!-- <h4>
                     <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        type="submit"
                        class="btn-icon rounded-circle ml-2"
                        @click="addField(k)"
                      >
                        <feather-icon icon="PlusIcon" />
                      </b-button>
                    </h4> -->
                <!-- <b-form-group label-for="uploadFile">
                    <b-form-file
                      v-model="file"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    />
                  </b-form-group> -->
              </b-col>
              <b-col>
                <div
                  id="one"
                  class="d-flex"
                  v-for="(input, k) in portNumbers"
                  :key="k"
                >
                  <!-- :key="`phoneInput-${index}`" -->
                  <div class="col-6 d-flex align-items-center">
                    <b-form-input
                      v-model="input.from_port"
                      class="col-11 mb-1"
                      placeholder="from port"
                    />
                  </div>
                  <div class="d-flex align-items-center col-6">
                    <!-- input.phone -->
                    <b-form-input
                      v-model="input.to_port"
                      class="col-11 mb-1"
                      placeholder="to port"
                    />
                    <b-button
                      v-show="portNumbers.length > 1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      type="submit"
                      class="btn-icon rounded-circle ml-2 mb-1"
                      @click="removeField(k)"
                    >
                      <feather-icon icon="MinusIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <b-button
          type="submit"
          variant="primary"
          class="mr-1 mt-1"
          @click.prevent="updateApplication(appid)"
        >
          Submit
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
// import axiosIns from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BCard,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      cloudName: "",
      profileType: "",
      profileOptions: [
        { value: "AWS", text: "AWS" },
        { value: "DGO", text: "Digital Ocean" },
        { value: "LightSail", text: "AWS LightSail" },
      ],
      token: "",
      applicationName: "",
      applicationLink: "",
      firewall: "",
      applicationDetails: "",
      appdetails: [],
      appid: "",

      firewalls: [],
      firewall_s: [],
      firewallOptions: [],
      firewall_options: [],
      cloudProfiles: "",
      cloudProfileOptionss: [],
      cloudProfileOptions: [],
      cloudid: "",
      portNumbers: [{ to_port: "", from_port: "" }],
      appdetailss: [],
      cloudProfiless: "",
      publicApplication: false,
      autoEnroll: false,
      // noChange: "",
    };
  },
  mounted() {
    // this.fetchGroup();
    this.getAppDetails();
    this.getCloudList();

    this.token = this.$store.state.app.token;
  },
  created() {
    // (this.tenantid = this.$store.getters.tenantid),
    //   (this.accesstoken = this.$store.getters.token);
    //   console.log(this.tenantid);
    this.appid = this.$route.params.id;
  },
  watch: {
    cloudProfiles() {
      this.fetchFirewall(this.cloudProfiles);
    },
  },
  methods: {
    back() {
      this.$router.push({ name: "home" });
    },
    getAppDetails: function (appid) {
      const options = {
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url:
          process.env.VUE_APP_BASEURL +
          `application/applications/${this.appid}/`,
      };
      var self = this;

      this.$http(options)
        .then((res) => {
          console.log(res.data);
          this.appdetails = res.data;
          this.applicationName = res.data.application_name;
          this.applicationLink = res.data.application_link;
          this.applicationDetails = res.data.description;
          this.cloudProfiles = res.data.firewalls[0].cloud_profile;
          this.publicApplication = res.data.is_public_application;
          this.autoEnroll = res.data.is_automatic_enroll;
          this.appdetailss = res.data.port;
          this.portNumbers = this.appdetailss;
          this.firewalls = res.data.firewalls;
          this.firewalls.forEach((ele) => {
            delete ele["cloud_profile_data"];
            this.firewall_s.push(ele);
          });
          // this.firewall_s = res.data.firewalls;
          // this.firewall_s.forEach((ele) => {
          //   delete ele["cloud_profile_data"];
          //   this.firewalls.push(ele);
          // });
          // this.cloudProfiles = res.data;

          // this.cloudProfiless = res.data.firewalls[0].cloud_profile_data;
          //   this.cloudProfiless.forEach((ele) => {
          //     ab.push(ele);
          //   });

          //   this.appdetailss.forEach((ele) => {
          //     // this.phoneNumbers.forEach((el1))
          //     this.phoneNumbers[0].to_port = ele.to_port;
          //     this.phoneNumbers[0].from_port = ele.from_port;

          //   })
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCloudList: function () {
      const options = {
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url: process.env.VUE_APP_BASEURL + `cloudprofile/cloudprofile`,
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          console.log(res.data);
          this.cloudProfileOptionss = res.data;
          //   this.cloudProfileOptionss.forEach((ele) => {
          //     this.cloudProfileOptions.push(ele);
          //   });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    firewallUnderCloud(value) {
      (this.firewalls = []),
        // this.firewall_options = [],
        (this.firewallOptions = []),
        this.fetchFirewall(this.cloudProfiles);
    },
    fetchFirewall(cloudid) {
      this.firewall_options = [];
      const options = {
        method: "GET",
        url:
          process.env.VUE_APP_BASEURL +
          `firewall/firewall/?cloud_profile=${cloudid}`,
      };
      this.$http(options).then((res) => {
        this.items = res.data;
        this.firewallOptions = res.data;
        this.firewallOptions.forEach((ele) => {
          delete ele["cloud_profile_data"];
          delete ele["applications"];
          this.firewall_options.push(ele);
        });
        console.log(this.firewallOptions, "firewallOptions");
      });
    },
    updateApplication(appid) {
      var self = this;

      let data = {
        application_name: this.applicationName,
        application_link: this.applicationLink,
        description: this.applicationDetails,
        // cloud_profile: this.cloudProfile,
        firewalls: this.firewalls,
        is_public_application: this.publicApplication,
        is_automatic_enroll: this.autoEnroll,
        port: JSON.parse(JSON.stringify(this.portNumbers)),
      };
      // if (this.groups) {
      //   let groups_list = [];
      //   groups_list.push(this.groups);
      //   data["groups"] = groups_list;
      // }
      const options = {
        method: "PUT",
        data: data,
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url:
          process.env.VUE_APP_BASEURL +
          `application/applications/${this.appid}/`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status === "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "res.data.message",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Edited Successfully",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.$router.push({ name: "home" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addField(k) {
      this.portNumbers.push({
        to_port: "",
        from_port: "",
      });
    },
    removeField(index, fieldType) {
      // fieldType.splice(index, 1);
      this.portNumbers.splice(index, 1);
      // fieldType.splice(index, 1);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
